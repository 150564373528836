<template>
    <v-row justify="center">
        <v-card style="width: 50%;">
            <v-card-title class="headline">
                <v-icon> {{ icons.mdiBug }}</v-icon>
                &nbsp;
                Reportar um problema
            </v-card-title>
            <v-form>
                <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="12">
                    <v-text-field
                        label="Titulo"
                        v-model="titulo"
                        :counter="100"
                        required
                    />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                    <v-textarea
                        label="Descrição"
                        v-model="descricao"
                        :counter="500"
                        required
                        placeholder="Informe com detalhes onde está o erro e como ele acontece."
                    />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <dropzone
                        @vdropzone-success="onFileUploadSuccess"
                        @vdropzone-file-uploading="fileIsLoading"
                        style="background: transparent !important; border: 1px #6a6a6a solid !important;"
                        id="image"
                        :options="dropzoneOptions"
                        ref="dropzone"
                        :use-custom-dropzone-options="true"
                        />
                            
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                v-on="on"
                                color="primary"
                                :disabled="btnSendDisabled"
                                outlined
                                @click="submitBugReport">Enviar</v-btn>
                            </template>
                            <span>Preencha os campos e se possível, enviar um print do erro.</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-card>

        <v-snackbar
        v-model="snackbar"
        :timeout="-1"
        >
      {{ this.toastText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    </v-row>
  </template>
  
<script>
import { mdiBug } from '@mdi/js'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Cookie from 'js-cookie'
import Usuario from '@/store/Usuario'
import { ref } from '@vue/composition-api'
import Strings from '@/common/strings'

  export default {
    components: {
        dropzone: vue2Dropzone
    },
    data() {
      return {
        titulo: '',
        descricao: '',
        dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailWidth: null,
            createImageThumbnails: true,
            maxFilesize: 0.5,
            maxFiles: 1,
            acceptedFiles: 'image/*',
            uploadMultiple: false,
            headers: { "My-Awesome-Header": "header value" },
            dictDefaultMessage: 'Clique ou arraste o arquivo aqui para enviar',
            dictFileTooBig: 'Arquivo muito grande ({{filesize}}MiB). Maximo permitido: {{maxFilesize}}MiB.',
            dictFallbackMessage: 'Seu navegador não suporta uploads de arquivos de arrastar e soltar.',
            dictFallbackText: 'Por favor, use o formulário alternativo abaixo para enviar seus arquivos como nos velhos tempos.',
            dictInvalidFileType: 'Você não pode fazer upload de arquivos deste tipo.',
            dictResponseError: 'O servidor respondeu com o código {{statusCode}}.',
            dictCancelUpload: 'Cancelar upload',
            dictCancelUploadConfirmation: 'Tem certeza de que deseja cancelar este upload?',
            dictRemoveFile: 'Remover arquivo',
            dictMaxFilesExceeded: 'Você não pode enviar mais arquivos.',
            addRemoveLinks: true
        },
        btnSendDisabled: false
      };
    },
    methods: {
        submitBugReport() {
            if(this.descricao == '' || this.titulo == ''){
                this.toastText = 'Preencha os campos corretamente'
                this.snackbar = true
                return
            }

            var imgBase64 = ''
            if(typeof this.imageBase64 != 'undefined' && this.imageBase64 != ''){
                imgBase64 = this.imageBase64.split(',')[1]
            }

            let payload = {
                Portal: "web",
                Titulo: this.titulo,
                Descricao: this.descricao,
                ArquivoBase64: imgBase64
            }

            this.$loading(true)

            Usuario.reportarBug(payload, Strings.imobiliariaId).then(result => {

                this.toastText = result.data
                this.snackbar = true
            }).catch(error => {

                if(error.response.status == 401){
                    Cookie.remove(Strings.cookie_token)
                    Cookie.remove(Strings.cookie_username)
                    this.$router.push('/pages/login')
                }

                this.toastText = error.response
                this.snackbar = true
            }).finally(() => {
                this.$loading(false)

                this.titulo = ''
                this.descricao = ''

                this.$refs.dropzone.removeAllFiles()
            })
        },
        onFileUploadSuccess (file, response) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                this.imageBase64 = reader.result
                this.btnSendDisabled = false
            }
        },
        fileIsLoading(file, xhr, formData){
            this.btnSendDisabled = true
            console.log('Arquivo sendo carregado: ', file.name)
        }
    },
    setup() {
        const snackbar = ref(false)
        const toastText = ref('')
    return {
      icons: {
        mdiBug
      },
      snackbar,
      toastText,
    }
  },
  };
  </script>
  
  <style>
  .headline {
    text-align: center;
    font-weight: bold;
  }
  </style>
  